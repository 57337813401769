import React from "react";
import { Desktop, Mobile, Tablet } from "../../Layout/Responsive";
import MobileTabletFooter from "./MobileTabletFooter";

export default function Footer() {
  return (
    <div >
      <MobileTabletFooter/>
    </div>
  );
}
